import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
//import { useLocation } from "react-router-dom";

export default function HelmetMetaData(props) {
  let location = useLocation();
  let currentUrl = "http://www.neurafarm.com" + location.pathname;
  let quote = props.quote !== undefined ? props.quote : "";
  let title = props.title !== undefined ? props.title : "Neurafarm - More Precise, More Impact";
  let ogTitle = "Neurafarm - More Precise, More Impact"
  let image = props.image !== undefined ? props.image : "https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG";
  let description = props.description !== undefined ? props.description  : "How to feed the world? How to grow enough food for everyone on the planet? How to create a more sustainable agriculture? " +
    "These are the questions we’re answering here at Neurafarm. Join our journey to empower farmer and business to produce more, reach more, and distribute better.";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#Neurafarm";
  return (
    <Helmet>
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta property="title" content={title} />
        <meta property="quote" content={quote} />
        <meta name="description" content={description} />
        <meta property="image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:quote" content={quote} />
        <meta property="og:hashtag" content={hashtag} />
        <meta property="og:image" content={image} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="Neurafarm" />
        <meta property="og:description" content={description} />    
    </Helmet>
  );
}
