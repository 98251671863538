import React from 'react'
import {Row, Col} from 'react-bootstrap'
import '../styles/footer.css'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {Link} from 'gatsby'

import { globalHistory } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"

import logoLinkedIn from "../images/linkedIn.png"
import logoTwitter from "../images/twitter.png"
import logoFacebook from "../images/facebook.png"
import NEURAFARM from '../images/Neurafarm-Logo-Samping.png'

/* <div>
                <div className = "footer-left">
                    Term and Conditions | Policy
                </div>

                <div className = "footer-right">
                    @ 2019 PT. Neura Cipta Nusantara. All rights reserved.
                </div>
            </div> 
            
<div className = "footer-container">
                <div id = "special-box">
                    <StaticImage src = {NEURAFARM} alt = "neurafarm-logo" width = "100px"/>
                    <div className = "neurafarm-moto">More Precise, More Impact</div>
                    <div className = "neurafarm-exp">
                        Smart Farming company. Improving productivity and efficiency in the agriculture industry. 
                        Digitizing Farms, creating a more resilient food system.
                    </div>
                    <div className = "footer-right-link">
                        <a href="/"><StaticImage src = {logoLinkedIn} className = "link-logo" alt = "logo-linkedIn"/></a>
                        <a href="/"><StaticImage src = {logoTwitter} className = "link-logo" alt = "logo-twitter"/></a>
                        <a href="/"><StaticImage src = {logoFacebook} className = "link-logo" alt = "logo-facebook"/></a>
                    </div>
                </div>

                <div className = "box">
                    <div className = "column-title">PRODUCTS</div>
                    <div className = "section-content"><Link to = "/" > Dokter Tania </Link></div>
                    <div className = "section-content"><Link to = "/" > Enterprise Solution </Link></div>
                </div>

                <div className = "box">
                    <div className = "column-title">COMPANY</div>
                    <div className = "section-content"><Link to = "/" > Who We Are </Link></div>
                    <div className = "section-content"><Link to = "/" > What We Do </Link></div>
                    <div className = "section-content"><Link to = "/" > Press & News </Link></div>
                    <div className = "section-content"><Link to = "/" > Careers </Link></div>
                    <div className = "section-content"><Link to = "/" > Contact Us </Link></div>
                </div>

                <div className = "box">
                    <div className = "column-title">RESOURCES</div>
                    <div className = "section-content"><Link to = "/" > Blog </Link></div>
                    <div className = "section-content"><Link to = "/" > Community </Link></div>
                    <div className = "section-content"><Link to = "/" > Press Kit </Link></div>
                </div>

                <div className = "box">
                    <div className = "column-title">CONTACT </div>
                    <div className = "section-content"> Ganesha St. 15F, Bandung, 40132, Indonesia </div>
                    <div className = "section-content"> hi@neurafarm.com </div>
                </div>
            </div>

            <Row className = "bottom-footer">
                <Col md={5} className = "bottom-footer-left-container">
                    <div className = "footer-left">Copyright © 2020 PT. Neura Cipta Nusantara. All rights reserved.</div>
                </Col>

                <Col md={7} className = "bottom-footer-right-container">
                    <div className = "footer-right">
                        <div className = "footer-right-text">Terms and Conditions & Privacy Policy</div> 
                    </div>
                </Col>
            </Row>
            
            <Link to = {`${globalHistory.location.pathname}/dokter-tania`} className = "section-content"> Dokter Tania</Link>
            */

const Footer = () => {
    return(
        <footer>
            <div className = "top-footer-container">

                <div className = "box-neurafarm">
                    <StaticImage id = "neurafarm-logo" src = {NEURAFARM} alt = "neurafarm-logo" />
                    <div className = "box-neurafarm-moto">More Precise, More Impact</div>
                    <div className = "box-neurafarm-exp">
                        Smart Farming company. Improving productivity and efficiency in the agriculture industry. 
                        Digitizing Farms, creating a more resilient food system.
                    </div>
                    <div className = "box-neurafarm-link">
                        <OutboundLink href="https://www.linkedin.com/company/neurafarm/"><StaticImage src = {logoLinkedIn} className = "link-logo" alt = "logo-linkedIn"/></OutboundLink>
                        <OutboundLink href="https://twitter.com/neurafarm"><StaticImage src = {logoTwitter} className = "link-logo" alt = "logo-twitter"/></OutboundLink>
                        <OutboundLink href="https://web.facebook.com/neurafarm/"><StaticImage src = {logoFacebook} className = "link-logo" alt = "logo-facebook"/></OutboundLink>
                    </div>
                </div>

                <div className = "box">
                    <div className = "box-left">
                        <div className = "column-title">PRODUCTS</div>
                        <div className = "section-content"> 
                            <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/dokter-tania" : "/dokter-tania"}>Dokter Tania</Link>
                        </div>
                        <div className = "section-content"> 
                            <Link to = "/404">Enterprise Solution</Link>
                        </div>
                    </div>
                    <div className = "box-right">
                        <div className = "column-title">COMPANY</div>
                        <div className = "section-content">
                            <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/who-we-are" : "/who-we-are"}>Who We Are</Link>
                        </div>
                        <div className = "section-content">
                            <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/what-we-do" : "/what-we-do"}>What We Do</Link>
                        </div>
                        <div className = "section-content">
                            <Link to = "/404">Press & News</Link>
                        </div>
                        <div className = "section-content">
                            <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/careers" : "/careers"}>Careers</Link>
                        </div>
                        <div className = "section-content">
                            <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/contact-us" : "/contact-us"}>Contact Us</Link>
                        </div>
                    </div>
                </div>

                <div className = "box">
                    <div className = "box-left">
                        <div className = "column-title">RESOURCES</div>
                        <div className = "section-content">
                            <Link to = "/404">Blog</Link>
                        </div>
                        <div className = "section-content">Community</div>
                        <div className = "section-content">Press Kit</div>
                    </div>
                    <div className = "box-right">
                        <div className = "column-title">CONTACT </div>
                        <div className = "section-content"> Ganesha St. 15F, Bandung, 40132, Indonesia </div>
                        <div className = "section-content"> hi@neurafarm.com </div>
                    </div>
                </div>
            </div>

            <div id = "footer-devider" />

            <Row className = "bottom-footer">
                <Col md={5} className = "bottom-footer-left-container">
                    <div className = "footer-left">Copyright © 2021 PT. Neura Cipta Nusantara. All rights reserved.</div>
                </Col>

                <Col md={7} className = "bottom-footer-right-container">
                    <div className = "footer-right">
                        <Link to = {globalHistory.location.pathname.includes("/id") ? "/id/privacy-policy" : "/privacy-policy"}>Terms and Conditions & Privacy Policy</Link>
                    </div>
                </Col>
            </Row>
            
        </footer>
    )
}

export default Footer;